import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate } from "react-router-dom";
import moment from "moment";
import SwalServices from "../../../services/swalServices/SwalServices";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import MembershipTaskListServices from "../../../services/axiosServices/apiServices/MembershipTaskListServices";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import { saveAs } from "file-saver";

import {
  CommonValidationMessages,
} from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";

import { encryptAES } from "../../../utils/Encryption";
import Selector from "../../../components/dropdown/commonDropdown/Selector";
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import MTOServices from "../../../services/axiosServices/apiServices/MTOServices";
import UpgradeChangeRequestTaskListProcess from "./UpgradeChangeRequestTaskListProcess";
import SendClarifications from "../membershipTaskList/SendClarifications"


export default class UpgradeChangeRequestTaskList extends Component {
  constructor(props) {
    super(props);
    this.TaskListServices = new MembershipTaskListServices();
    this.corporateMemberServices = new CorporateMemberServices();
    this.individualMemberServices = new IndividualMemberService();
    this.mtoServices = new MTOServices();
    this.swalServices = new SwalServices();
    this.state = {
      subscriptionArray: [],
      oldsubscriptionArray: [],
      emailSubscription: [],
      oldemailSubscription :[],
      isValidStartUpMember: true,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: "",
        dateTo: "",
        filterByStatus: "",
      },
      totalResultes: "",
      taskList: [],
      isLoading: false,
      isLoadingPayment: false,
      isLoadingReject: false,
      isLoadingApprove: false,
      isLoadingClarification: false,
      route: null,
      redirect: null,
      clarification: '',
      mrMrs: [],
      membershipType: [],
      billingCategories: [],
      statusList: [
        {
          value: 2,
          label: "Submitted",
        },
        {
          value: 3,
          label: "Sent For Clarification",
        },
        {
          value: 6,
          label: "Sent For Payment",
        },
        {
          value: 7,
          label: "Approved",
        },
      ],
      requestType: 0,
      oldCountry: [],
      newCountry: [],
      country: [],
      taskDetails: {
        bussinessCard: "",
        newExperienceYears: "",
        newOfficeDID: "",
        newBussinessMobile: 0,
        newEmailSubscription: "",
        taskId: 0,
        memberId: 0,
        contactRoleId: 0,
        billingCategoryId: 0,
        subscriptionStartDate: null,
        subscriptionEndDate: null,
        membershipTaskStatusId: 0,
        transactionTypeId: 0,
        approvalDate: null,
        isEndorsed: false,
        isCouncilApproved: false,

        membershipTypeId: 0,
        organisationName: "",
        organisationUEN: "",
        incorporationDate: null,
        paidUpCapital: 0,
        servicesTypeId: 0,
        industriesServedId: 0,
        associationBenefitsId: 0,
        generalEmailAddress: "",
        generalBusinessTelephone: null,
        statusId: 0,
        endorsersId1: 0,
        endorsersId2: 0,
        representativeId1: 0,
        representativeId2: 0,
        IsRejected: 0,
        designation: '',
        firstName: '',
        lastName: '',
        entranceFee: 0,
        annualFee: 0,
        includedChapter: 0,
        additionalChapterPrice: 0,
        subtotalPrice: 0,
        gstPrice: 0,
        discountedPrice: 0,
        totalPrice: 0,
        paymentsCreditsApplied: 0,
        netTotal: 0,
        membersReceiptId: 0,
        subscriptionFee: 0,
        membershipPaymentTypeId: 0,
        transactionId: "",
        voucherCode: "",
        membershipPaymentInvoicePath: '',
        endorsementSatusForEndorser1: 0,
        endorsementSatusForEndorser2: 0,

        //mtoregistryData
        mtoRegistryId: 0,
        insuranceCompanyName: '',
        policyNumber: '',
        expiryDate: null,
        insurancePolicyFilePath: '',
        approvalDate: null,
        mtoSubscriptionStartDate: null,
        mtoSubscriptionEndDate: null,
        mtoCertificateNo: '',
        mtoCertificateFilePath: '',

        //UpgradeChange Data
        upgradeChangeRequestId: 0,
        membershipTaskStatusId: '',
        membershipTypeId: 0,
        organisationName: '',
        organisationUEN: '',
        registrationDate: '',
        requestTypeId: 0,
        requestTypeName: '',
        membershipTypeName: '',

        //UpgradeChange Process Data
        ceoCurrentExp: '',
        ceoNewExp: '',
        changeReqTypeId: 0,
        existingData: '',
        newACRAFilePath: '',
        newData: '',
        oldACRAFilePath: '',
        reqId: 0,
        terminationDate: '',
        terminationReason: '',
      },
      ceoDetails: {
        bussinessMobile: 0,
        contactRoleId: 0,
        createdAppId: null,
        createdBy: null,
        designation: '',
        emailAddress: '',
        emailSubscriptionId: '',
        experienceYears: '',
        firstName: '',
        lastName: '',
        memberId: 0,
        mrMrsId: 0,
        officeDID: '',
        sameAsFirstFlag: false,
        yearsId: 0,
        navigatorewBussinessMobile: "",
      },
      terminationTypeArr: [
        { id: 3, name: "Membership Termination" },
        { id: 4, name: "MTO Termination" }
      ],
      terminationDateArr: [],
      setSendClarifications: false,
      setDropdownOpen: false,
      membershipTaskListProcessModal: false,
      setAddeditModalOpen: false,
      setPaymentLink: false,
      sendQuotation: false,
      sendInvoice: false,
      sendEscalation: false,
      sendReject: false,
      sendEscalate: {
        memberId: 0,
        firstName: "",
        lastName: "",
        organisationName: "",
        organisationUEN: "",
        generalEmailAddress: "",
        generalBusinessTelephone: 0,
        internalNotes: "",
      },
      validationRule: {
        membershipTypeId: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Membership Type'),
          },
        ],

        //Organisation Profile Validation Rules
        organisationName: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'Organisation Name'),
          },
        ],
        organisationUEN: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'Organisation UEN'),
          },
        ],
        incorporationDate: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Incorporation Date'),
          },
        ],
        subscriptionStartDate: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Commencement Date'),
          },
        ],
        subscriptionEndDate: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Commencement Date'),
          },
        ],
        paidUpCapital: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'Paid Up Capital'),
          },
        ],
        // mailingCountryId: [
        //   {
        //     type: 'require',
        //     message: Messages.CommonValidationMessages.SelectRequired.replace('{0}', 'Country of Mailing Address'),
        //   },
        // ],

        //Other Information Validation Rules
        servicesTypeData: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Types of Services Your Organisation Offers'),
          },
        ],
        industriesServedData: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Types of Industries Your Organisation Serves'),
          },
        ],
        associationBenefitsId: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Your Organisation Benefits from Joining The Association'),
          },
        ],

        //Endorsers
        endorsersId1: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Original Member Endorser'),
          },
        ],
        endorsersId2: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Endorser Company'),
          },
        ],
        representativeId1: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Representative'),
          },
        ],
        representativeId2: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Representative'),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      actionDropdown: ["Process", "Delete"],
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAllDropdownsForTaskList();
  }

  //ACRA PDF Download 
  // getInvoiceReceiptPdfByBookingId = (bookingId, type) => {
  //   let reqParams = [bookingId, type];
  //   this.individualMemberServices.getInvoiceReceiptPdfByBookingId(reqParams).then((response) => {
  //     if (response != null) {
  //       var file = new Blob([response.data], { type: "application/pdf" });
  //       var filename = "";

  //       if (type === "InsurancePolicy") {
  //         filename = "InsurancePolicy";
  //       } else if (type === "ChangeCompanyNameAddressACRA") {
  //         filename = "LatestACRAFile";
  //       } else if (type === "UpgradeLatestACRA") {
  //         filename = "LatestACRAFile";
  //       }
  //       // const fileURL = URL.createObjectURL(file);
  //       //Open the URL on new Window
  //       //  const pdfWindow = window.open();
  //       //  pdfWindow.location.href = fileURL;   
  //       saveAs(file, filename + ".pdf");
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //   });
  // };
  getInvoiceReceiptPdfByBookingId = (bookingId, type) => {
    let reqParams = [bookingId, type];
    this.individualMemberServices.getInvoiceReceiptPdfByBookingId(reqParams).then((response) => {
      if (response != null) {
        var file = new Blob([response.data], { type: "application/pdf" });
        var filename = "";

        filename = this.state.taskDetails.bussinessCard || this.state.taskDetails.newACRAFilePath;

        // const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        //  const pdfWindow = window.open();
        //  pdfWindow.location.href = fileURL;   
        saveAs(file, filename);
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getAllUpgradeChangeRequestList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.corporateMemberServices.getAllUpgradeChangeRequestList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.registrationDate = moment(key.registrationDate).format("DD MMMM, YYYY");
          if (key.generalEmailAddress === null || key.generalEmailAddress === "") {
            key.generalEmailAddress = "-";
          }
          if (key.organisationName === null || key.organisationName === "") {
            key.organisationName = "-";
          }
          if (key.organisationUEN === null || key.organisationUEN === "") {
            key.organisationUEN = "-";
          }
          if (key.registrationDate === null || key.registrationDate === "") {
            key.registrationDate = "-";
          }
          return key;
        });
        this.setState({ taskList: details });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllDropdownsForTaskList = () => {
    this.TaskListServices.getAllDropdownsForTaskList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let membershipType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipType");
        let mrMrs = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MrMrs");
        let billingCategories = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BillingCategory");
        let emailSubscription = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EmailSubscription");
        let oldemailSubscription = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EmailSubscription");

        let country = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Countries");
        emailSubscription = emailSubscription.map((p) => ({

          value: p.id,
          label: p.name,
        }));
        oldemailSubscription = oldemailSubscription.map((p) => ({

          value: p.id,
          label: p.name,
        }));
        this.setState(
          {
            membershipType: membershipType,
            mrMrs: mrMrs,
            billingCategories: billingCategories,
            emailSubscription: emailSubscription,
            oldemailSubscription:oldemailSubscription,
            country: country
          },
          () => this.getAllUpgradeChangeRequestList()
        );
      } else {
        this.swalServices.Error(response.message);
        this.getAllUpgradeChangeRequestList();
      }
      this.setState({ isLoading: false });
    });
  };

  refreshTable = () => {
    // Fetch data again or perform any action to refresh the table
  }

  rejectUpgradeChangeRequestById = () => {
    let id = this.state.taskDetails.upgradeChangeRequestId;
    if (id > 0 && id != null) {
      let detail = this.state.taskDetails;
      detail.IsRejected = true;
      this.setState({ taskDetails: detail })
      this.setState({ isLoadingReject: true });

      this.corporateMemberServices.rejectUpgradeChangeRequestById(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ sendReject: true }, () =>
            this.swalServices.Success("Request Rejected Successfully!"),
            this.setState({ setAddeditModalOpen: false }),
            this.setState({ isLoadingReject: false }),
            this.getAllUpgradeChangeRequestList()
          );
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingReject: true });
      })
    }
    this.getAllUpgradeChangeRequestList()
  }

  approveUpgradeChangeRequest = () => {
    let request = { ...this.state.taskDetails };
    request.isApproved = 1;

    // let subscriptionArray = this.state.subscriptionArray || []; // Fallback to an empty array if undefined

    // let request2 = subscriptionArray
    //   .map(labelObj => labelObj.value)
    //   // .join(', ');

    // request.newEmailSubscription = request2;
    if (this.state.subscriptionArray.length > 0) {
      var arr1 = this.state.subscriptionArray.map(function (item) { delete item.bad; return item.value; });
      request.newEmailSubscription = arr1.join([","]);
    }

    // if (request.changeReqTypeId === 3) {
    //   request.terminationReqFor = "Membership Termination"
    // } else {
    //   request.terminationReqFor = "MTO Termination"
    // }
    if (request.terminationDate === 2) {
      request.terminationDate = this.state.taskDetails.subscriptionEndDate
    } else {
      request.terminationDate = new Date();
    }
    this.setState({ isLoadingApprove: true });

    if (this.state.taskDetails.approvalDate === undefined || this.state.taskDetails.approvalDate === "") {
      request.approvalDate = new Date();
    }

    this.corporateMemberServices.approveUpgradeChangeRequest(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Request Approved Successfully!")
        this.setState({ isLoadingApprove: false });
        window.location.reload();
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingReject: true });
    })
  }

  handleChangeMultiDropdown(selected, identity) {
    if (identity === "emailSubscription") {
      const maxSelections = 12;
      const limitedSelections = selected.slice(0, maxSelections);
      this.setState({
        subscriptionArray: limitedSelections
      });
    }
  }


  saveMainImage = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "application/pdf") ||
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/msword"
    ) {
      if (file) {
        let request = {
          storagePath: "BussinessCard",
          file: file,
          fileName: file.name
        };

        this.corporateMemberServices.uploadPDF(request).then((response) => {
          if (response != null) {
            let detail = this.state.taskDetails;
            detail["bussinessCard"] = response.fileName;
            this.setState({ taskDetails: detail }, () => {
              this.swalServices.Success("File Uploaded Successfully.");
            });
          } else {
            this.swalServices.Error("Failed to upload PDF file.");
          }
        });


      }
    }
    else if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      let request = {};
      request.storagePath = "BussinessCard";
      request.file = file;
      request.fileName = file.name;

      this.corporateMemberServices.uploadPDF(request).then((response) => {
        if (response != null) {

          let detail = { ...this.state.taskDetails };
          detail["bussinessCard"] = response.fileName;

          this.setState({ taskDetails: detail }, () => {
            this.swalServices.Success("File Uploaded");
          });

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      this.swalServices.Error("No file selected.");
    }
  };



  getUpgradeChangeRequestDetailById = (upgradeChangeRequestId) => {
    this.corporateMemberServices.getUpgradeChangeRequestDetailById(upgradeChangeRequestId).then((response) => {
      let desiredId = response.responseItem.responseContent.membershipTypeId;
      let foundIndex = this.state.membershipType.find(
        (item) => item.id === desiredId
      ) ? this.state.membershipType.find(
        (item) => item.id === desiredId
      ).id : undefined;

      if (desiredId === foundIndex) {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState(
            { taskDetails: response.responseItem.responseContent },
            () => {
              let data = { ...this.state.taskDetails };
              this.setState({ taskDetails: data }, () => {
                if (this.state.taskDetails.newEmailSubscription && this.state.taskDetails.newEmailSubscription !== 0 && this.state.taskDetails.newEmailSubscription !== "0") {
                  var array = (this.state.taskDetails.newEmailSubscription !== null || this.state.taskDetails.newEmailSubscription !== "") ? this.state.taskDetails.newEmailSubscription.split(',') : [];
                  let newemailSubscriptionDataArray = [];
                  const filteredArray = array.filter(value => value !== "0");
                  if (filteredArray && filteredArray.length > 0) {
                    filteredArray.forEach(topic => {
                      newemailSubscriptionDataArray.push(this.state.emailSubscription.find(topics => topics.value === Number(topic)));
                    });
                  }
                  this.setState({ subscriptionArray: newemailSubscriptionDataArray })
                }
                if (this.state.taskDetails.emailSubscriptionId && this.state.taskDetails.emailSubscriptionId !== 0 && this.state.taskDetails.emailSubscriptionId !== "0") {
                  var array = (this.state.taskDetails.emailSubscriptionId !== null || this.state.taskDetails.emailSubscriptionId !== "") ? this.state.taskDetails.emailSubscriptionId.split(',') : [];
                  let newwemailSubscriptionDataArray = [];
                  const filteredArray = array.filter(value => value !== "0");
                  if (filteredArray && filteredArray.length > 0) {
                    filteredArray.forEach(topic => {
                      newwemailSubscriptionDataArray.push(this.state.oldemailSubscription.find(topics => topics.value === Number(topic)));
                    });
                  }
                  this.setState({ oldsubscriptionArray: newwemailSubscriptionDataArray })
                }
              });
              this.getContactInformationDetailById(data.memberId)
            },
            () => this.setState({ validState: { isValid: true, error: {} } })
          );
          this.setState({ setAddeditModalOpen: true });
          // this.setState(prevState => ({
          //   taskDetails: {
          //     ...prevState.taskDetails,
          //     ceoCurrentExp: upgradeChangeData.ceoCurrentExp,
          //     ceoNewExp: upgradeChangeData.ceoNewExp,
          //     changeReqTypeId: upgradeChangeData.changeReqTypeId,
          //     existingData: upgradeChangeData.existingData,
          //     newACRAFilePath: upgradeChangeData.newACRAFilePath,
          //     newData: upgradeChangeData.newData,
          //     oldACRAFilePath: upgradeChangeData.oldACRAFilePath,
          //     reqId: upgradeChangeData.reqId,
          //     terminationDate: upgradeChangeData.terminationDate,
          //     terminationReason: upgradeChangeData.terminationReason,
          //     upgradeChangeRequestId: upgradeChangeData.upgradeChangeRequestId
          //   }
          // }));
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  getContactInformationDetailById = (id) => {
    if (id > 0 && id != null) {
      let request = [id]
      this.corporateMemberServices.getContactInformationDetailById(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          let taskDetails = this.state.taskDetails;
          taskDetails.contactInformationDetail = details
          this.setState({ taskDetails: taskDetails });

          let ceoDetails = details[0]
          this.setState({ ceoDetails: ceoDetails })
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  setSendClarificationsFlag = () => {
    this.setState({ setSendClarifications: true });
  }

  handleChangeClarificationDetails = (data) => {
    if (typeof data === "string") {
      this.setState({
        clarification: data,
      });
    }
  };

  saveClarificationDetails = () => {
    this.setState({ setSendClarifications: true });
    let mailFirstName = ""
    let mailLastName = ""
    let mailEmailAddress = ""
    if (this.state.taskDetails.contactInformationDetail[0].sameAsFirstFlag) {
      mailFirstName = this.state.taskDetails.contactInformationDetail[0].firstName
      mailLastName = this.state.taskDetails.contactInformationDetail[0].lastName
      mailEmailAddress = this.state.taskDetails.contactInformationDetail[0].emailAddress
    } else {
      mailFirstName = this.state.taskDetails.contactInformationDetail[1].firstName
      mailLastName = this.state.taskDetails.contactInformationDetail[1].lastName
      mailEmailAddress = this.state.taskDetails.contactInformationDetail[1].emailAddress
    }

    let request = {
      memberId: this.state.taskDetails.memberId,
      upgradeChangeRequestId: this.state.taskDetails.upgradeChangeRequestId,
      clarificationDetails: this.state.clarification,
      organisationName: this.state.taskDetails.organisationName,
      organisationUEN: this.state.taskDetails.organisationUEN,
      firstName: mailFirstName,
      lastName: mailLastName,
      emailAddress: mailEmailAddress,
      changeReqTypeId: this.state.taskDetails.changeReqTypeId
    };
    this.setState({ isLoadingClarification: true });

    this.corporateMemberServices.changeRequestSendClarificationMail(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ setSendClarifications: false });
        this.setState({ clarification: "" });
        this.swalServices.Success("Clarification Mail Sent Successfully!")
        window.location.reload()
        this.setState({ sendEscalation: true }, () =>
          this.setState({ setAddeditModalOpen: false }),
        );
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingClarification: false });
    });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllUpgradeChangeRequestList(newPagination);
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.taskDetails;
    if (drpIdentity === "MembershipType") {
      detail["membershipTypeId"] = id;
      this.validateField("membershipTypeId");
    }
    if (drpIdentity === "BillingCategories") {
      detail["billingCategoryId"] = id;
      //this.validateField("billingCategoryId");
    }
    this.setState({ taskDetails: detail });
    let details = this.state.taskDetails;
    if (drpIdentity === "newCountry") {
      details["newCountry"] = id;
      // this.validateField("newCountry");
    }
    this.setState({ taskDetails: details });
  };

  handleChangeDropdown = (index, fieldName, value) => {
    const { taskDetails } = this.state;
    let updatedContactInfo = [...taskDetails.contactInformationDetail];
    updatedContactInfo[index] = {
      ...updatedContactInfo[index],
      [fieldName]: value
    };

    this.setState({
      taskDetails: {
        ...taskDetails,
        contactInformationDetail: updatedContactInfo
      }
    });
  };

  setFilterParametersForList = (id, drpIdentity) => {
    let detail = this.state.pagination;
    if (drpIdentity === "ApplicationStatus") {
      detail["filterByStatus"] = id;
    }
    this.setState({ pagination: { ...detail } });
    this.getAllUpgradeChangeRequestList();
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllUpgradeChangeRequestList();
  };

  handleChangeForPagination(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getAllUpgradeChangeRequestList();
      } else if (fulldate === null) {
        this.getAllUpgradeChangeRequestList();
      }
    });
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.taskDetails };
    detail[name] = value;
    this.setState({ taskDetails: detail });
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  selectTask = (id, id2, e) => {
    e.stopPropagation();
    if (id > 0 && id2 > 0) {
      this.getUpgradeChangeRequestDetailById(id2);
    }
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }

  actionClick = (index, value, option, event) => {
    if (option === "Process") {
      var id = this.state.taskList[index].memberId;
      var id2 = this.state.taskList[index].upgradeChangeRequestId;
      this.setState({ selectedTaskId: this.state.taskList[index].taskId },
        () => {
          this.selectTask(id, id2, event);
        }
      );
      var requestTypeId = this.state.taskList[index].requestTypeId;
      this.setState({ requestType: requestTypeId })
    } else if (option === "Delete") {
      this.deleteTaskListDetailsByTaskId(value);
    }
  };

  deleteTaskListDetailsByTaskId = (companyId) => {
    this.TaskListServices.deleteTaskListDetailsByTaskId(companyId).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Membership Task List Entry Deleted Successfully."
          );
          let resetpag = {
            pageNo: 1,
            pageSize: 10,
            orderByColumn: "",
            orderFlag: 0,
            searchText: "",
            status: 0,
            dateFrom: null,
            dateTo: null,
            filterByStatus: 0,
          };
          this.setState({ pagination: resetpag }, () => {
            this.getAllUpgradeChangeRequestList();
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  actions = (element, index, value) => {
    return element !== "upgradeChangeRequestId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            value={value}
            id={index}
            index={index}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  onProcessModalClose = (e) => {
    e.stopPropagation();
    this.setState({ setAddeditModalOpen: false });
  };

  updateCorporateMemberUpdate = (value) => {
    if (value && value > 0) {
      const encryptedValue = encryptAES(value);
      const newUrl = '/CreateCorporateMembers/CorporateMemberId?=' + encryptedValue;
      window.open(newUrl, '_blank');
    }
  }

  handleChangeInDropdown = (selected, drpIdentity) => {
    let page = this.state.pagination;
    if (drpIdentity === "ApplicationStatus") {
      if (selected.value === 0) {
        page.filterByStatus = "";
      } else {
        page.filterByStatus = selected.label;
      }
      this.setState({ pagination: page });
      this.getAllUpgradeChangeRequestList();
    }
  };

  setTerminationFilterParameters = (id, drpIdentity) => {
    let detail = { ...this.state.taskDetails };
    if (drpIdentity === "TerminationType") {
      let terminationType = this.state.terminationTypeArr.find(x => x.id === id);
      if (terminationType) {
        detail["changeReqTypeId"] = terminationType.id;
      } else {
        detail["changeReqTypeId"] = "";
      }
      this.setState({ taskDetails: detail });
    }
    else if (drpIdentity === "TerminationDate") {
      detail["terminationDate"] = id;
      this.setState({ taskDetails: detail });
    }
  };

  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    let detail = this.state.taskDetails;
    detail[name] = checked;

    if (this.state.taskDetails.registeredNoUnitNumber === true && this.state.taskDetails.mailingAddressFlag === true) {
      detail.mailingNoUnitNumber = true
    }
    else {
      detail.mailingNoUnitNumber = false
    }
    this.setState({ taskDetails: { ...detail } });
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white pl-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-5 lg:col-span-6 col-span-12">
                <h2 className="text-xl theme-color">Date</h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateFrom}
                      onChange={(e) => this.handleChangeForPagination(e)}
                    />
                  </div>
                  <div className=" 2xl:col-span-1 lg:col-span-2 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateTo}
                      onChange={(e) => this.handleChangeForPagination(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-3 lg:col-span-4 col-span-12">
                <h2 className="pl-8 block text-xl font-medium  theme-color">
                  Application Status
                </h2>
                <div className="pl-8 border-l-[1px] border-[#757575]">
                  <Selector
                    options={this.state.statusList}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={this.handleChangeInDropdown.bind(this)}
                    allowSelectAll={false}
                    value={this.state.statusList.find(
                      (item) =>
                        item.label === this.state.pagination.filterByStatus
                    )}
                    drpIdentity="ApplicationStatus"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
              {`Total:${this.state.totalResultes}`}
            </h2>
            <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
              <div className="text-[#757575] text-xl">Search by Company </div>
              {/* <input className="col-span-2 text-lg font-bold  h-[40px] w-full text-[#757575]"/> */}
              <input
                id="default"
                className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        { name: "registrationDate", title: "Registration Date" },
                        { name: "generalEmailAddress", title: "Email Address" },
                        { name: "organisationName", title: "Organisation Name" },
                        { name: "organisationUEN", title: "Organisation UEN" },
                        { name: "membershipTypeName", title: "Membership Type" },
                        { name: "requestTypeName", title: "Type of Request" },
                        { name: "upgradeChangeStatusId", title: "Application Status" },
                        { name: "upgradeChangeRequestId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.taskList}
                      sortingColumns={[""]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "upgradeChangeRequestId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="member-task-process">
          <UpgradeChangeRequestTaskListProcess
            setAddeditModalOpen={this.state.setAddeditModalOpen}
            membershipType={this.state.membershipType}
            mrMrs={this.state.mrMrs}
            billingCategories={this.state.billingCategories}
            taskDetails={this.state.taskDetails}
            validState={this.state.validState}
            setModalOpen={() => this.setState({ setAddeditModalOpen: false })}
            onClose={(e) => { e.stopPropagation(); this.setState({ setAddeditModalOpen: false }); }}
            validateField={this.validateField.bind(this)}
            setFilterParameters={this.setFilterParameters.bind(this)}
            setSendClarificationsFlag={this.setSendClarificationsFlag.bind(this)}
            closeModalOnBack={() => { this.setState({ setAddeditModalOpen: false }); }}
            isLoading={this.state.isLoading}
            isLoadingApprove={this.state.isLoadingApprove}
            isLoadingClarification={this.state.isLoadingClarification}
            isLoadingReject={this.state.isLoadingReject}
            handleChange={this.handleChange.bind(this)}
            rejectUpgradeChangeRequestById={this.rejectUpgradeChangeRequestById.bind(this)}
            updateCorporateMemberUpdate={this.updateCorporateMemberUpdate.bind(this)}
            getInvoiceReceiptPdfByBookingId={this.getInvoiceReceiptPdfByBookingId.bind(this)}
            ceoDetails={this.state.ceoDetails}
            requestTypeId={this.state.requestType}
            approveUpgradeChangeRequest={this.approveUpgradeChangeRequest.bind(this)}
            setTerminationFilterParameters={this.setTerminationFilterParameters.bind(this)}
            subscriptionArray={this.state.subscriptionArray}
            oldsubscriptionArray={this.state.oldsubscriptionArray}
            emailSubscription={this.state.emailSubscription}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
            oldCountry={this.state.country}
            handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
          />
        </div>

        <div className="send-clarification-model">
          <SendClarifications
            clarificationModal={this.state.setSendClarifications}
            clarification={this.state.clarification}
            setOpenModal={() => this.setState({ setSendClarifications: false })}
            onTinyEceEditorChange={(data) =>
              this.handleChangeClarificationDetails(data)
            }
            isLoading={this.state.isLoadingClarification}
            // onClose={(e) => {e.stopPropagation();
            //   this.setState({ setSendClarifications: false });
            // }}
            // getClarificationDetails={this.getClarificationDetails.bind(this)}
            saveClarificationDetails={this.saveClarificationDetails.bind(
              this
            )}
          />
        </div>

        {/* <div className="membership-task-list-process-sec">
          <SendForApprove
            organisationName={this.state.taskDetails.organisationName}
            generalEmailAddress={this.state.taskDetails.generalEmailAddress}
            setSendApproveModal={this.state.setSendApprove}
            setOpenModal={() => this.setState({ setSendApprove: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setSendApprove: false });
            }}
          />
          
          
          <EscalateModal
            organisationName={this.state.taskDetails.organisationName}
            generalEmailAddress={this.state.taskDetails.generalEmailAddress}
            setSendInvoiceModal={this.state.sendEscalation}
            setOpenModal={() => this.setState({ sendEscalation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendEscalation: false });
              window.location.reload();
            }}
          />
        </div> */}
      </>
    );
  }
}
