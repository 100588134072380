import {axiosGetMultiParams, axiosGetAuthorize, axiosPostAuthorize, axiosGetFileAuthorizeblob, axiosPostFileAuthorizeblob } from '../AxiosRequests';
import { ApproveRenewMembershipRequest,AddEditMembershipApprovalDate,AddMembershipPaymentData,AddEditTask,RejectApplicationForMember,AddEditMembershipTaskAndSendForApproval, AddEditMembershipTaskAndSendPaymentLink, GetAllBillingCategories, GetAllCorporateProspectSource, GetAllDropdownsForMembershipTaskList, GetAllMembershipRevenue, GetAllMembershipTypes, GetAllPriceTables, GetAllSources, GetAllTasksList, GetAllTransactionaTypes, GetCalculatedFinalPrice, GetExistingDiscountTableByTaskId, GetPriceTabelByCorporateMemberId, GetTaskByCompanyId, SendInvoiceEmailToCorporateMember, SendQuotationEmailToCorporateMember, SendEscalateMailToMember, DeleteTaskListDetailsByTaskId,GetContactInformationDetailById, SendClarificationMailToMember, GetAllDetailsForGeneratePDF} from '../ApiEndPoints'

export default class MembershipTaskListServices {

    async getAllTaskList(request) {
        return axiosPostAuthorize(GetAllTasksList, request)
    }
    
    async getTaskByCompanyId(request) {
        return axiosGetAuthorize(GetTaskByCompanyId, request)
    }
    async addEditMembershipTaskAndSendForApproval(request) {
        return axiosPostAuthorize(AddEditMembershipTaskAndSendForApproval, request)
    }
    async getAllSources() {
        return axiosGetAuthorize(GetAllSources);
    }

    async getAllMembershipTypes() {
        return axiosGetAuthorize(GetAllMembershipTypes);
    }

    async getAllTransactionaTypes() {
        return axiosGetAuthorize(GetAllTransactionaTypes);
    }

    async getAllBillingCategories() {
        return axiosGetAuthorize(GetAllBillingCategories);
    }

    async getAllMembershipRevenue() {
        return axiosGetAuthorize(GetAllMembershipRevenue);
    }
    async getPriceTabelForTaskListProcess(request) {
        return axiosPostAuthorize(GetAllPriceTables, request)
    }
    async getSourceLists() {
        return axiosGetAuthorize(GetAllCorporateProspectSource);
    }
    async getAllDropdownsForTaskList() {
        return axiosGetAuthorize(GetAllDropdownsForMembershipTaskList);
    }
    async getPriceTabelByCorporateMemberId(request) {
        return axiosGetAuthorize(GetPriceTabelByCorporateMemberId, request)
    }
    async getCalculatedFinalPrice(request) {
        return axiosPostAuthorize(GetCalculatedFinalPrice, request)
    }
    async addEditMembershipTaskAndSendPaymentLink(request) {
        return axiosPostAuthorize(AddEditMembershipTaskAndSendPaymentLink, request)
    }
    async getExistingDiscountTableByTaskId(request) {
        return axiosGetAuthorize(GetExistingDiscountTableByTaskId, request)
    }
    async sendInvoiceEmailToCorporateMember(request) {
        return axiosPostAuthorize(SendInvoiceEmailToCorporateMember, request);
    }
    async sendQuotationEmailToCorporateMember(request) {
        return axiosPostAuthorize(SendQuotationEmailToCorporateMember, request);
    }
    async sendEscalateMailToMember(request) {
        return axiosPostAuthorize(SendEscalateMailToMember, request);
    }
    async deleteTaskListDetailsByTaskId(request) {
        return axiosGetAuthorize(DeleteTaskListDetailsByTaskId, request);
    }
    async rejectApplicationForMember(request) {
        return axiosPostAuthorize(RejectApplicationForMember, request);
    }
    async saveClarificationDetails(request) {
        return axiosPostAuthorize(SendClarificationMailToMember, request);
    }
   
    async addEditTask(request) {
        return axiosPostAuthorize(AddEditTask, request)
    }
    async approveRenewMembershipRequest(request) {
        return axiosPostAuthorize(ApproveRenewMembershipRequest, request)
    }
    async addMembershipPaymentData(request) {
        return axiosPostAuthorize(AddMembershipPaymentData, request);
    }
    async getAllDetailsForGeneratePDF(request) {
        return axiosGetFileAuthorizeblob(GetAllDetailsForGeneratePDF, request);
    }

}
