import React from "react";
import ModalBasic from '../../../pages/component/ModalBasic'
import moment from "moment";

const RepresentativeDetailsModal = (props) => {
    const data = props.getMemberDirectoryData[props.councilIndex];

    return (
        <ModalBasic
            id="show-representative-details-modal"
            modalOpen={props.setRepresentativeDetailsModal}
            setModalOpen={props.setOpenModal}
            title={data.organisationName || "-"}
        >
            <>
                <div className="p-6 bg-white rounded-lg shadow-lg mx-5">
                    <div className="grid grid-cols-2 gap-4">
                        {data.firstRepresentativeName !== null && data.firstRepresentativeEmail !== null && data.firstRepresentativeNumber !== null ?
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                                    First Representative
                                </h3>
                                <p className="text-sm text-gray-700"><b>Name:</b> {data.firstRepresentativeName || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Email Address:</b> {data.firstRepresentativeEmail || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Contact Number:</b> {data.firstRepresentativeNumber || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Office DID:</b> {data.firstRepresentativeOfficeDID || '-'}</p>
                            </div>
                            :
                            null
                        }

                        {data.secondRepresentativeName !== null && data.secondRepresentativeEmail !== null && data.secondRepresentativeNumber !== null ?
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                                    Second Representative
                                </h3>
                                <p className="text-sm text-gray-700"><b>Name:</b> {data.secondRepresentativeName || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Email Address:</b> {data.secondRepresentativeEmail || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Contact Number:</b> {data.secondRepresentativeNumber || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Office DID:</b> {data.secondRepresentativeOfficeDID || '-'}</p>
                            </div>
                            :
                            null
                        }

                        {data.hrExecutiveName !== null && data.hrExecutiveEmail !== null && data.hrExecutiveNumber !== null ?
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                                    HR Executive
                                </h3>
                                <p className="text-sm text-gray-700"><b>Name:</b> {data.hrExecutiveName || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Email Address:</b> {data.hrExecutiveEmail || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Contact Number:</b> {data.hrExecutiveNumber || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Office DID:</b> {data.hrExecutiveOfficeDID || '-'}</p>
                            </div>
                            :
                            null
                        }

                        {data.financeExecutiveName !== null && data.financeExecutiveEmail !== null && data.financeExecutiveNumber !== null ?
                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h3 className="font-semibold text-[#292E8F] mb-2 text-2xl">
                                    Finance Executive
                                </h3>
                                <p className="text-sm text-gray-700"><b>Name:</b> {data.financeExecutiveName || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Email Address:</b> {data.financeExecutiveEmail || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Contact Number:</b> {data.financeExecutiveNumber || '-'}</p>
                                <p className="text-sm text-gray-700"><b>Office DID:</b> {data.financeExecutiveOfficeDID || '-'}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
            </>
        </ModalBasic>
    );
};

export default RepresentativeDetailsModal;
